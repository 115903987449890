<script>
  import { mapActions } from "vuex";
  import InputComment from "./InputComment.vue";
  import Likes from "../likes/Likes.vue";
  import { socket } from "@/socket";
  import { scrollBottom } from "@/js/utils";

  export default {
    data() {
      return {
        showModalViewers: false,
        viewers: [],
      };
    },
    props: ["scrollToBottom", "contest", "totalViewers", "title", "comments", "showComments", "showAnimateTitle"],
    components: { Likes, InputComment, UsersListVisor: () => import("../../../components/widgets/Modals/UsersListVisor.vue") },
    computed: {
      IsAdmin() {
        return this.$userData.role === "superadmin";
      },
      payload() {
        return { item: this.contest._id, component: "Competition" };
      },
      latestComments() {
        return this.comments.slice(0, 3);
      },
      contestType() {
        return this.$route.params.type;
      },
      isMoreThan1024() {
        return this.$store.state.screen.width >= 1024;
      },
    },
    methods: {
      ...mapActions(["setItemViewed", "getItemViewersTotal", "getItemViewers"]),
      showUsersView(mode) {
        this.showModalViewers = mode;
        this.$emit("openVisor", mode ? this.contest._id : "");
      },
      scrollToCommentsBox(block) {
        const commentBox = document.querySelector(".commentsList");
        const options = { behavior: "smooth", block };
        scrollBottom(commentBox, options);
      },
      sendComment(comment) {
        socket.emit("newCommentItem", { comment, item: this.contest._id, component: "Competition" });
        this.scrollToCommentsBox("end");
      },
      goToComments() {
        if (!this.scrollToBottom) return;
        this.scrollToCommentsBox("start");
      },
      openLikes(dataLikers = []) {
        if (!dataLikers.length) {
          return;
        }

        const text = "Los usuarios que dieron like:";
        const likers = dataLikers.map(({ user }) => user);
        const listUsers = { likers, text };
        this.$emit("listOfLikes", listUsers);
      },
    },
  };
</script>

<template>
  <div class="commentsBar" :style="{ maxWidth: showComments ? '1440px' : '1200px' }">
    <div class="commentsBar__titleContainer" v-if="title && showAnimateTitle">
      <img src="../../../assets/img/BellIcon.svg" alt="bellIcon" class="commentsBar__icon" />
      <div class="commentsBar__titleAnimate">
        <span class="commentsBar__textTitle">{{ title }}</span>
      </div>
    </div>
    <p :class="`commentsBar__title ${contestType !== 'yearly' ? 'commentsBar__title--monthly' : ''}`" v-if="title && !showAnimateTitle">{{ title }}</p>
    <div class="commentsBar__input" v-if="showComments && !isMoreThan1024">
      <InputComment @textInput="sendComment" />
    </div>
    <div :class="`commentsBar__info ${!title ? 'commentsBar__info--noTitle' : ''}`">
      <div :class="`commentsBar__input ${!title ? 'commentsBar__input--noTitle' : ''}`" v-if="showComments && isMoreThan1024">
        <InputComment @textInput="sendComment" />
      </div>
      <div class="commentsBar__views">
        <div class="commentsBar__showviews" @click="showUsersView(true)" v-if="IsAdmin && totalViewers">
          <iconic name="eye_contets" class="commentsBar__eye" />
          <span class="commentsBar__number">{{ totalViewers }}</span>
        </div>
        <Likes :likes="contest.likers || []" :showLikes="true" :payload="payload" :openLikes="() => openLikes(contest.likers)" />
        <div class="commentsBar__redirect" @click="goToComments">
          <div class="commentsBar__commentmobile">
            <iconic name="comments" class="" />
          </div>
          <div class="commentsBar__avatars" v-if="latestComments.length > 0">
            <Avatar class="commentsBar__avatar" v-for="(user, idx) in latestComments" :key="idx" :user="user"> </Avatar>
          </div>
          <span class="commentsBar__number">{{ comments.length }}</span>
          <span class="commentsBar__text">Comentarios</span>
        </div>
        <button class="commentsBar__button" v-if="isMoreThan1024" @click="goToComments">Ver opiniones</button>
      </div>
      <button class="commentsBar__button" v-if="!isMoreThan1024" @click="goToComments">Ver opiniones</button>
    </div>
  </div>
</template>

<style lang="scss">
  .commentsBar {
    @include Flex(column, space-between, flex-start);
    width: 100%;
    gap: 10px;
    &__listViewers {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0;
    }
    &__commentmobile {
      display: flex;
      font-size: 20px;
    }
    &__text {
      display: none;
      font-family: $newFont_regular;
      font-size: 16px;
    }
    &__titleContainer {
      width: 100%;
      position: relative;
      border-radius: $radius-48;
      background: linear-gradient(89.89deg, #bd0909 -6.49%, #ff1a4b 111.68%);
    }
    &__textTitle {
      margin-left: $space-40;
      display: inline-block;
    }
    &__iconHover {
      transform: translateY(18px) rotate(7deg);
      transition: transform 0.7s ease-in-out;
    }
    &__titleHover {
      &::after {
        left: 150%;
      }
      &::before {
        transform: translateY(-11px) rotate(4deg);
      }
      background-color: #ff0000;
      transform: translateY(2px) rotate(-4deg);
    }
    &__icon {
      position: absolute;
      top: 10px;
      left: -4px;
      width: 47px;
      height: 47px;
      z-index: 3;
      pointer-events: none;
      transition: transform 0.5s ease-in-out;
      .commentsBar__titleContainer:hover & {
        transform: translateY(18px) rotate(-7deg);
      }
    }
    &__title {
      color: $primary-color;
      font-family: $newFont_regular;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      width: 65%;
      &--monthly {
        width: unset;
      }
    }
    &__titleAnimate {
      color: #fff;
      font-family: $newFont_regular;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      text-transform: uppercase;
      padding: $space-12;
      border-radius: $radius-48;
      border: 1px solid white;
      background: linear-gradient(89.89deg, #bd0909 -6.49%, #ff1a4b 111.68%);
      transition: all 0.5s ease-in-out;
      overflow: hidden;
      z-index: 2;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -200%;
        width: 200%;
        height: 100%;
        background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
        border-radius: $radius-48;
        transition: all 0.9s ease-in-out;
        z-index: 1;
      }

      &:hover::after {
        left: 190%;
      }

      &:hover::before {
        transform: translateY(-11px) rotate(4deg);
      }

      &:hover {
        background-color: #ff0000;
        transform: translateY(2px) rotate(-4deg);
      }
    }
    &__input {
      width: 100%;
    }
    &__redirect {
      display: flex;
      gap: 8px;
    }
    &__views {
      @include Flex(row, center, center);
      cursor: pointer;
      gap: 8px;
    }

    &__eye {
      font-size: 26px;
      transition: 0.3s ease-in-out;
      padding: 4px;
      &:hover {
        background: rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }
    }

    &__number {
      font-size: 16px;
      font-family: $newFont_regular;
    }

    &__info {
      @include Flex(row, space-between, center);
      gap: 10px;
      width: 100%;
    }
    &__showviews {
      @include Flex(row, center, center);
      gap: 8px;
      z-index: 2;
    }
    &__button {
      @include Flex(row, center, center);
      width: 123px;
      height: 27px;
      font-family: $newFont_semiBold;
      font-size: 16px;
      border: none;
      padding: 4px 12px;
      border-radius: 50px;
      background: $primary-color;
      backdrop-filter: blur(2px);
      color: #fff;
      white-space: nowrap;
    }
    &__smile {
      font-size: 24px;
    }
    .InputComment {
      background: #fff;
      &__input {
        background: #fff;
      }
    }

    &__avatars {
      @include Row(flex-start, center);
      display: none;
      border-radius: 12px;
      border: solid 1px #fff;
      background: #fff;
    }
    &__avatar {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      &:nth-child(2) {
        margin-left: -9px;
        z-index: 1;
      }
      &:last-child {
        margin-left: -9px;
        z-index: 0;
      }
      &:first-child {
        margin-left: 0;
        z-index: 1;
      }
    }
    @media (min-width: 768px) {
      &__icon {
        top: 0px;
        left: -20px;
        width: 40px;
        height: 40px;
      }
      &__textTitle {
        margin-left: $space-8;
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 45px;
      background: rgba(243, 243, 243, 0.79);
      backdrop-filter: blur(6.5px);
      padding: 20px;
      max-height: 80px;
      &__info {
        width: 70%;
        &--noTitle {
          width: 100%;
        }
      }
      &__input {
        width: 57%;
        &--noTitle {
          width: 100%;
        }
      }
      &__titleContainer,
      &__title {
        width: 29%;
      }
    }
    @media screen and (min-width: 1380px) {
      &__input {
        width: 52.5%;
        &--noTitle {
          width: 100%;
        }
      }
      &__info {
        width: 67.8%;
        &--noTitle {
          width: 100%;
        }
      }
    }
    @media screen and (min-width: 1440px) {
      gap: 20px;
      &__commentmobile {
        display: none;
      }
      &__redirect {
        gap: $space-8;
      }
      &__avatars,
      &__text {
        display: flex;
      }
    }
  }
</style>
